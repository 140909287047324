import { FC, useState, useEffect } from "react";
import {
  AdvancedMarker,
  Map,
  InfoWindow,
  useAdvancedMarkerRef,
  useMap,
} from "@vis.gl/react-google-maps";
import { Site as SiteType } from "../api/shareparkApi";

const defaultProps = {
  center: {
    // sydney
    lat: -33.91277075825478,
    lng: 151.20202097822738,
  },
  zoom: 10,
};

const SiteMap: FC<{
  apiKey: string;
  sites: SiteType[];
  setSelectedSite: (id: string) => void;
}> = ({ sites, setSelectedSite }) => {
  console.log(sites);

  const map = useMap();
  const [center, setCenter] = useState(defaultProps.center);
  const [zoom, setZoom] = useState(defaultProps.zoom);

  useEffect(() => {
    if (!map || !sites || sites.length === 0) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();

    sites.forEach((site) => {
      bounds.extend(new google.maps.LatLng(site.latitude, site.longitude));
    });

    // Fit map to bounds
    map.fitBounds(bounds);

    // Optional: Store center and zoom after fitBounds()
    google.maps.event.addListenerOnce(map, "idle", () => {
      const currentZoom = map.getZoom() || defaultProps.zoom;
      map.setZoom(Math.max(currentZoom - 1, 0)); // Prevent zoom from going below 0

      setCenter({
        lat: map.getCenter()?.lat() || defaultProps.center.lat,
        lng: map.getCenter()?.lng() || defaultProps.center.lng,
      });
      setZoom(Math.max(currentZoom - 1, 0)); // Update zoom state
    });
  }, [sites, map]);

  console.log(center, zoom);

  return (
    <Map
      mapId="site-map"
      style={{ width: "100%", height: "400px" }}
      defaultCenter={center}
      defaultZoom={zoom}
    >
      {sites.map((site) => (
        <Site
          key={site.siteId}
          site={site}
          onClick={() => setSelectedSite(site.siteId)}
        />
      ))}
    </Map>
  );
};

const Site: FC<{ site: SiteType; onClick: () => void }> = ({
  site,
  onClick,
}) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        position={{ lat: site.latitude, lng: site.longitude }}
        onClick={() => onClick()}
        onMouseEnter={() => setInfowindowOpen(true)}
        onMouseLeave={() => setInfowindowOpen(false)}
      >
        <div>
          <img
            src="/images/shareparkSquareLogoMapIcon.svg"
            alt="site"
            style={{ height: "40px", width: "40px" }}
          />
        </div>
      </AdvancedMarker>
      {infowindowOpen && (
        <InfoWindow
          anchor={marker}
          onCloseClick={() => setInfowindowOpen(false)}
        >
          <div>
            <div>
              <strong>{site.siteName}</strong>
            </div>
            {/* <div>{site.city}</div>
            <div>
              <label style={{ marginRight: "1em" }}>Capacity: </label>
              {site.currentCapacity}
            </div> */}
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default SiteMap;
