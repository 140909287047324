import React, { useContext, useState, useEffect, FC, useCallback } from "react";
import firebaseApp from "./firebase";
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  sendEmailVerification,
  User,
} from "firebase/auth";
import {
  Invoice,
  Parker,
  Reservation,
  Site,
  SiteParker,
  StripeMetadataItem,
  Transaction,
  Vehicle,
} from "../api/shareparkApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export type Profile = {
  parker: Parker;
  reservations: Reservation[];
  publicSites: Site[];
  permSites: SiteParker[];
  vehicles: Vehicle[];
  validationErrors: string[];
  transactions: Transaction[];
  invoices: Invoice[];
};

export const firebaseAuth = getAuth(firebaseApp);

const AuthContext = React.createContext<{
  currentUser: User | null;
  loading: boolean;
  logout: () => Promise<void>;
  token: string | null;
  profile: Profile | null | undefined;
  sendEmailVerification: () => Promise<boolean>;
  setProfile: (p: Profile | null | undefined) => void;
  checkValidToken: () => void;
}>({
  currentUser: null,
  loading: true,
  logout: async () => {},
  token: null,
  profile: null,
  sendEmailVerification: async () => false,
  setProfile: () => {},
  checkValidToken: () => {},
});

export const useAuth = () => useContext(AuthContext);
export const useProfile = () => {
  const { profile } = useContext(AuthContext);
  if (profile == null) {
    throw new Error("Profile is null");
  }
  return profile;
};

export const AuthProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const spUrl =
    window.location.hostname.indexOf(".sharepark.net") > -1
      ? window.location.hostname.replace("app", "api")
      : "localhost:5001";

  const [currentUser, setCurrentUser] = useState<User | null>(null); // this wiil be the data blob
  const [loading, setLoading] = useState<boolean>(true);

  const [token, setToken] = useState<string | null>(null);
  const [emailVerified, setEmailVerified] = useState<boolean>(false); //not needed?

  const { data: profile } = useQuery<Profile>(
    ["profile"],
    async () => {
      const user = currentUser!;
      const idToken = await user.getIdToken(/*forceRefresh*/ false);
      setToken(idToken);

      // now we've got the token. send to backend
      const metadata = user.toJSON() as { [key: string]: unknown };
      metadata.token = idToken;

      setEmailVerified(user.emailVerified);

      const profile = await fetch("https://" + spUrl + "/public/profile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(metadata),
      }).then((response) => response.json());

      // materialise payment details
      if (profile.parker.stripeMetadata != null) {
        var payTemp = JSON.parse(
          profile.parker.stripeMetadata
        ) as StripeMetadataItem[];

        profile.parker.paymentDetails = [payTemp[0]];

        // Get the current date
        var currentDate = new Date();

        // Extract the current month and year
        var currentMonth = currentDate.getMonth() + 1; // January is 0, so we add 1
        var currentYear = currentDate.getFullYear();

        // Get the expiration month and year from the JSON
        var expirationMonth = payTemp[0].Card.ExpMonth; // Replace with your actual value from JSON
        var expirationYear = payTemp[0].Card.ExpYear; // Replace with your actual value from JSON

        // Compare the expiration date with the current date
        if (
          expirationYear < currentYear ||
          (expirationYear === currentYear && expirationMonth < currentMonth)
        ) {
          profile.parker.hasValidPayment = false;
          profile.parker.hasExpiredPayment = true;
        } else {
          profile.parker.hasValidPayment = true;
        }
      } else {
        profile.parker.hasValidPayment = false;
      }

      // materialise permSites from JSON metadata
      profile.permSites = profile.permSites.map((siteParker: SiteParker) => {
        if (siteParker.publicDescription != null) {
          let metadata = JSON.parse(siteParker.publicDescription);

          siteParker.siteName = metadata.siteName;
          siteParker.siteAddress = metadata.siteAddress;
          siteParker.siteTimeZone = metadata.siteTimeZone;
          siteParker.latitude = metadata.lat;
          siteParker.longitude = metadata.lng;
          siteParker.siteId = metadata.siteId;
        }

        return siteParker;
      });

      return profile;
    },
    {
      enabled: !!currentUser,
    }
  );

  const client = useQueryClient();

  const setProfile = useCallback(
    (profile: Profile | null | undefined) => {
      // console.log("setting profile", profile);
      client.setQueryData(["profile"], profile);
    },
    [client]
  );

  function logout() {
    return signOut(firebaseAuth);
  }

  async function sendEmailVerificationInner() {
    if (currentUser) {
      await sendEmailVerification(currentUser, {
        url: "https://" + window.location.host + "/",
      });
    }
    return true;
  }

  const checkValidToken = () => {
    // this should get a new token if the current one will expire in 5 minutes
    currentUser
      ?.getIdToken(false)
      .then((idToken) => {
        setToken(idToken); //set the token in the global state
      })
      .catch((error) => {});
  };

  // sub to auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      setCurrentUser(user);
      setLoading(false);

      if (user == null) {
        setToken(null);
        setProfile(null);
        setEmailVerified(false);
        return;
      }
    });

    return () => {
      unsubscribe();
    };
  }, [setProfile]);

  const value = {
    currentUser,
    loading,
    logout,
    token,
    profile,
    sendEmailVerification: sendEmailVerificationInner,
    emailVerified,
    setProfile,
    checkValidToken,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
