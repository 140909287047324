import { Col, Container, Row, Table } from "react-bootstrap";
import { useProfile } from "../auth/authContext";
import { FC } from "react";
import { SiteParker, useApi } from "../api/shareparkApi";
import SiteMap from "./SiteMap";

const Locations = () => {
  const profile = useProfile();
  const { config } = useApi();
  const apiKey = config?.googleMapsApi || "";

  const setSelectedSite = (id: string) => {
    console.log("Selected site", id);
  };

  return (
    <Container className="px-5">
      <Row className="gx-2 my-5">
        <Col>
          <h2>Your Permanent Parking Locations</h2>
          <>
            {profile.permSites.map((siteParker) => (
              <ParkingLocation siteParker={siteParker} key={siteParker.id} />
            ))}
            {profile.permSites.length === 0 && (
              <div>You have no permanent parking locations</div>
            )}
          </>
        </Col>
      </Row>

      {profile.permSites.length > 0 && (
        <SiteMap
          apiKey={apiKey}
          sites={profile.permSites}
          setSelectedSite={setSelectedSite}
        />
      )}
    </Container>
  );
};

const dayOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
] as const;

const ParkingLocation: FC<{ siteParker: SiteParker }> = ({ siteParker }) => {
  const getTimeString = (time: number) => {
    if (time === 0) {
      return "Midnight";
    }

    if (time === 24) {
      return "Midnight";
    }

    if (time < 12) {
      return time + " AM";
    }

    if (time === 12) {
      return time + " PM";
    }

    return time - 12 + " PM";
  };

  //const accessDays = siteParker.accessTimes;

  return (
    <Container className="g-3 my-3 py-2 bg-white reservation">
      <Row>
        <Col>{siteParker.siteName}</Col>
      </Row>
      <Row>
        <Col>{siteParker.siteAddress}</Col>
      </Row>
    </Container>
  );
};

export default Locations;
