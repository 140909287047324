import { Col, Container, Row, Spinner } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../api/shareparkApi";
import QRCode from "qrcode.react";

export const Visitor = () => {
  const { id } = useParams();
  const { spUrl } = useApi();
  const { data: visitor, isLoading } = useQuery(["visitor", id], async () => {
    const response = await fetch(`https://${spUrl}/public/visitor/${id}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

  if (isLoading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" />
      </Container>
    );
  }

  return (
    <Container className="nav-justified g-0 flex-column flex-md-row px-3 py-3">
      <Row className="h-100 align-items-center mb-2">
        <Col>
          <Image src={"/images/sharepark-logo.svg"} width="180px" />
        </Col>
      </Row>
      <Row className="h-100 align-items-center">
        <Col>
          <h3>Reservation Details</h3>

          <p>Your visitor reservation at:</p>
          <p>
            {visitor.name}, {visitor.address}
          </p>
          <p className="mt-2">
            from: {visitor.startDate} {visitor.startTime}
            <br />
            until: {visitor.endDate} {visitor.endTime}
          </p>
          <p className="mt-2">is now confirmed. Scan this QrCode to enter:</p>
          <QRCode
            value={visitor.id}
            size={300}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={false}
            renderAs={"svg"}
          />

          <p className="mt-2">
            You can turn up at the site and park up to {visitor.gracePeriod}{" "}
            minutes prior to your reservation. Just follow the instructions
            provided to find your bay.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
//
