import { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../custom.css";
import ButtonWrapper from "./ButtonWrapper";
import { useProfile } from "../auth/authContext";
import type { Reservation as ReservationType } from "../api/shareparkApi";
import { useApi } from "../api/shareparkApi";
import { useNavigate, useParams } from "react-router-dom";
import ReservationDetails from "./ReservationDetails";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { useQuery } from "@tanstack/react-query";

const Reservations = () => {
  return (
    <Container className="px-5">
      <Row className="gx-2 my-5">
        <Col lg>
          <YourReservations />
        </Col>
      </Row>
    </Container>
  );
};

const YourReservations = () => {
  const profile = useProfile();
  const { fetchReservations, fetchSites } = useApi();

  let { id } = useParams();
  const navigate = useNavigate();

  const { data: reservations, isLoading } = useQuery({
    queryKey: ["reservations"],
    queryFn: fetchReservations,
  });

  const { data: sites, isLoading: sitesLoading } = useQuery({
    queryKey: ["sites"],
    queryFn: fetchSites,
  });

  const reservation = reservations?.find((r) => r.id === id) || null;

  const onReservationClicked = (reservation: ReservationType) => {
    navigate(`/reservations/${reservation.id}`);
  };

  const showNewEditForm = () => {
    navigate(`/reservations/00000000-0000-0000-0000-000000000000`);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Row>
        <Col>
          <h2>Your Reservations</h2>
          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          {profile.parker.stripeMetadata !== null ||
          profile.permSites.length > 0 ? (
            <div style={{ width: "110px" }} className="mb-3">
              <ButtonWrapper
                buttonText="+ Add"
                fullWidth={true}
                onClick={() => showNewEditForm()}
              />
            </div>
          ) : (
            <div className="alert alert-danger">
              Please add a payment method first
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {reservations &&
            reservations.map((reservation) => (
              <Reservation
                reservation={reservation}
                key={reservation.id}
                onReservationClicked={() => onReservationClicked(reservation)}
              />
            ))}
          {reservations && reservations.length === 0 && (
            <div>You have no reservations</div>
          )}
        </Col>
      </Row>
      {id && (
        <ReservationDetails
          reservation={reservation as ReservationType}
          profile={profile}
          sites={sites || []}
        />
      )}
    </>
  );
};

const Reservation: FC<{
  reservation: ReservationType;
  onReservationClicked: () => void;
}> = ({ reservation, onReservationClicked }) => {
  const startDate = reservation.startDate
    ? new Date(reservation.startDate)
    : new Date();
  const endDate = reservation.endDate
    ? new Date(reservation.endDate)
    : new Date();

  const dateSuffix = (d: number) => {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <Container className="reservation mb-2">
      <Row>
        <Col
          className="bg-white curserPointer p-2 "
          md={6}
          onClick={() => onReservationClicked()}
        >
          <div className="text-wrap">{reservation.siteName}</div>
          <div className="dateString text-wrap">
            {startDate.toLocaleDateString("en-AU", {
              weekday: "short",
              day: "numeric",
            }) + dateSuffix(startDate.getDate())}
            &nbsp;
            {startDate.toLocaleDateString("en-AU", {
              month: "long",
              year: "numeric",
            })}
            ,&nbsp;{getTimeString(reservation.startTime)}
          </div>
          <div className="dateString text-wrap">
            {endDate.toLocaleDateString("en-AU", {
              weekday: "short",
              day: "numeric",
            }) + dateSuffix(endDate.getDate())}
            &nbsp;
            {endDate.toLocaleDateString("en-AU", {
              month: "long",
              year: "numeric",
            })}
            ,&nbsp;{getTimeString(reservation.endTime)}
          </div>

          <Container className="g-2">
            {reservation.price ? reservation.price.toFixed(2) : "-"} -{" "}
            {reservation.status}
          </Container>
        </Col>
        <Col md={6}>
          <AddToCalendarButton
            name={"Parker reservation @ " + reservation.siteName}
            options={[
              "Apple",
              "Google",
              "Yahoo",
              "Microsoft365",
              "Outlook.com",
              "iCal",
            ]}
            startDate={reservation.startDate}
            endDate={reservation.endDate}
            startTime={reservation.startTime}
            endTime={reservation.endTime}
            timeZone={reservation.siteTimezone}
            description="Parking Reservation"
            location={reservation.siteAddress}
          />
        </Col>

        {reservation.error ? (
          <Col md={12} className="p-2">
            <div className="alert alert-danger m-2">{reservation.error}</div>
          </Col>
        ) : null}
      </Row>
    </Container>
  );
};

const getTimeString = (time: string | undefined) => {
  if (!time) return;

  if (time < "12:00") {
    return time + " AM";
  }

  if (time < "13:00") {
    return time + " PM";
  }

  const timeFragments = time.split(":");
  const newTime =
    Number(timeFragments[0]) - 12 + ":" + timeFragments[1] + " PM";
  return newTime;
};

export default Reservations;
