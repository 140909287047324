import { FC } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "react-toastify/dist/ReactToastify.css";
import { Login } from "./auth/Login";
import { Layout } from "./components/Layout";
import { AuthProvider, useAuth } from "./auth/authContext";
import { ApiProvider } from "./api/shareparkApi";
import { Visitor } from "./components/Visitor";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ApiProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Navigate replace to="/qr-code" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/visitor/:id" element={<Visitor />} />
              <Route
                path="/:tab/:id"
                element={
                  <PrivateRoute>
                    <Layout />
                  </PrivateRoute>
                }
              />
              <Route
                path="/:tab"
                element={
                  <PrivateRoute>
                    <Layout />
                  </PrivateRoute>
                }
              />
            </Routes>
            <ToastContainer position="top-center" />
          </Router>
        </ApiProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

const PrivateRoute: FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const { loading, currentUser } = useAuth();
  const isAuthenticated = !loading && currentUser;

  if (!isAuthenticated) {
    return (
      <Navigate
        replace
        to={{ pathname: "/login" }}
        state={{ from: location }}
      />
    );
  }

  return <>{children}</>;
};

export default App;
